import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class';

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SelectBrand',
    component: () => import('../pages/SelectBrand'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/select-category',
    name: 'SelectCategory',
    component: () => import('../pages/SelectCategory'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/select-sub-category',
    name: 'SelectSubCategory',
    component: () => import('../pages/SelectSubCategory'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../pages/Products'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/product/:productId',
    name: 'Product',
    component: () => import('../pages/ProductNew'),
    meta: {
      requiresAuth: true,
      bodyClass: 'product-page'
    }
  },
  {
    path: '/compare-products',
    name: 'CompareProducts',
    component: () => import('../pages/CompareProducts'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../pages/Profile'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('../pages/ForgetPassword')
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ForgetPasswordConfirm',
    component: () => import('../pages/ForgetPasswordConfirm')
  },
  {
    path: '/forget-password-success',
    name: 'ForgetPasswordSuccess',
    component: () => import('../pages/ForgetPasswordSuccess')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../pages/Signup')
  },
  {
    path: '/signup-successful',
    name: 'SignupSuccessful',
    component: () => import('../pages/SignupSuccessful')
  },
  {
    path: '/activate/:uid/:token',
    name: 'Activate',
    component: () => import('../pages/Activate')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/Login')
  },
  {
    path: '/engineer-performance',
    name: 'EngineerPerformance',
    component: () => import('../pages/EngineerPerformance'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../pages/Admin'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/redeem',
    name: 'Redeem',
    component: () => import('../pages/Redeem')
  },
  {
    path: '/redeem/thanks',
    name: 'Thanks',
    component: () => import('../pages/RedeemThanks')
  },
  {
    path: '/public/compare-products/:permalink',
    name: 'CompareProductsPublicTemplate',
    component: () => import('../pages/Public/CompareProducts')
  },
  {
    path: '/public/product/:permalink',
    name: 'ProductPublicTemplate',
    component: () => import('../pages/Public/Product')
  },
  {
    path: '/public/find-successor-model',
    name: 'FindSuccessorModel',
    component: () => import('../pages/Public/FindSuccessorModel')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../pages/NotFound')
  },
  {
    path: '/engineer-additional-referral',
    name: 'EngineerAdditionalReferral',
    component: () => import('../pages/EngineerAdditionalReferral'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
